let _profile = null;

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        $_accountRoles() {
          return _profile && _profile.roles ? _profile.roles : [];
        },
        $_profilePerson() {
          return _profile && _profile.person ? _profile.person : {};
        },
        $_schoolClasses() {
          return _profile && _profile.schoolClasses
            ? _profile.schoolClasses.sort((a, b) =>
                a.code.localeCompare(b.code)
              )
            : [];
        },
        $_classTeacherOf() {
          return _profile && _profile.classTeacherOf
            ? _profile.classTeacherOf
            : [];
        },
        $_currentTerm() {
          return _profile && _profile.currentTerm ? _profile.currentTerm : {};
        },
      },
      methods: {
        $_hasRole(roles) {
          if (Array.isArray(roles)) {
            for (let i = 0; i < roles.length; ++i) {
              if (this.$_accountRoles.includes(roles[i])) return true;
            }
            return false;
          }
          return this.$_accountRoles.includes(roles);
        },
        $_isPerson(person) {
          return (
            _profile &&
            _profile.person &&
            person &&
            _profile.person.id === person.id
          );
        },
        $_isClassTeacherOf(student) {
          return this.$_classTeacherOf.some(
            (el) => el.id == student.schoolClass.id
          );
        },
        async $_loadProfile() {
          _profile = await this.apiList({ resource: "app/profile" });
        },
      },
    });
  },
};
