export default [
  {
    path: "/departments",
    name: "Departments",
    component: () => import("@/views/Departments/"),
    children: [
      {
        path: ":id",
        name: "DepartmentDetail",
        component: () => import("@/views/Departments/Detail.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/screen",
    name: "Screen",
    component: () => import("@/views/Screen/"),
  },
  {
    path: "/roles",
    name: "Roles",
    component: () => import("@/views/Index/Roles/"),
    children: [
      {
        path: ":id",
        name: "RoleDetail",
        component: () => import("@/views/Index/Roles/Role"),
        props: true,
      },
    ],
  },
  {
    path: "/documents",
    component: () => import("@/views/Documents/"),
    redirect: "/documents/cat",
    children: [
      {
        path: "search/:search?",
        name: "DocumentsSearch",
        component: () => import("@/views/Documents/Search"),
        props: true,
        children: [
          {
            path: "document/:docId",
            name: "DocumentSearch",
            component: () => import("@/views/Documents/Document"),
            props: true,
          },
        ],
      },
      {
        path: "cat/:catId*",
        name: "DocumentCategories",
        component: () => import("@/views/Documents/Categories"),
        props: true,
        children: [
          {
            path: "document/:docId",
            name: "Document",
            component: () => import("@/views/Documents/Document"),
            props: true,
            alias: "/document/:docId",
          },
        ],
      },
    ],
  },
  {
    path: "/links",
    name: "Links",
    component: () => import("@/views/Links/"),
    props: {
      title: "Linksammlung",
      color: "error",
      page: "school/links",
    },
  },
  {
    path: "/gallery",
    name: "Galleries",
    component: () => import("@/views/Gallery/"),
  },
  {
    path: "/gallery/:gallery",
    name: "Gallery",
    component: () => import("@/views/Gallery/Gallery.vue"),
    props: true,
    children: [
      {
        path: ":image",
        name: "GalleryImage",
        component: () => import("@/views/Gallery/Image"),
        props: true,
      },
    ],
  },

  {
    path: "/finance/",

    component: () => import("@/views/Finance/"),
    children: [
      {
        path: "",
        name: "FinanceStudent",
        redirect: { name: "FinanceOwn" },
      },
      {
        path: "canton",
        name: "FinanceCanton",
        component: () => import("@/views/Finance/Finance.vue"),
        props: { type: "cantonal" },
      },
      {
        path: "own",
        name: "FinanceOwn",
        component: () => import("@/views/Finance/Finance.vue"),
        props: { type: "own" },
      },
      {
        path: "print",
        name: "StudentPrint",
        component: () => import("@/views/Finance/StudentPrint.vue"),
      },
    ],
  },
];
