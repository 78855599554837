<template>
  <v-container style="height: 100%">
    <v-app-bar :color="color" rounded dark>
      <v-app-bar-nav-icon @click.stop="toggleDrawer"
        ><img :src="require('@/assets/logo.svg')" height="24"
      /></v-app-bar-nav-icon>
      <v-toolbar-title
        >{{ title }} <slot v-if="$slots.title" name="title"></slot
      ></v-toolbar-title>
      <v-spacer></v-spacer>
      <slot v-if="$slots.icons" name="icons"></slot>
      <v-dialog v-else v-model="dialog" max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab x-small outlined color="white" v-bind="attrs" v-on="on">
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-system-bar window class="mb-2"
            >Hilfe<v-spacer /><v-btn icon @click="dialog = false"
              ><v-icon>mdi-close</v-icon></v-btn
            ></v-system-bar
          >
          <template v-if="$slots.help">
            <v-card-text>
              <slot name="help"></slot>
            </v-card-text>
          </template>

          <v-card-text
            >Hast du Fragen, Probleme oder Vorschläge?
            <a href="mailto:support@gymkirchenfeld.ch?subject=intern-app"
              >support@gymkirchenfeld.ch</a
            ></v-card-text
          >
          <v-divider />
          <v-card-actions>
            <v-btn text to="/faq">zu den FAQs</v-btn>

            <v-spacer />
            <v-btn text @click="dialog = false">schliessen</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <template v-slot:extension v-if="$slots.extension">
        <slot name="extension"></slot>
      </template>
    </v-app-bar>

    <slot name="before"></slot>
    <div style="margin-bottom: 130px" class="mt-4">
      <slot />
    </div>
    <slot name="after"></slot>
  </v-container>
</template>
<script>
export default {
  name: 'Page',
  props: { color: String, title: String },
  data: () => ({
    dialog: false,
  }),

  methods: {
    toggleDrawer() {
      this.$root.toggleDrawer();
    },
  },
};
</script>
<style scoped>
.v-toolbar {
  transition: 1000ms;
}
</style>
