export default [
  {
    path: "/course",
    name: "Courses",
    component: () => import("@/views/Courses/"),
  },
  {
    path: "/course/:id",
    component: () => import("@/views/Courses/Course"),
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    },
    children: [
      {
        path: "",
        name: "Course",
        redirect: { name: "CourseList" },
      },
      {
        path: "list",
        name: "CourseList",
        component: () => import("@/views/Courses/CourseList"),
      },
      {
        path: "panel",
        name: "CoursePanel",
        redirect: { name: "CourseList" },
      },
      {
        path: "register",
        name: "CourseRegister",
        component: () => import("@/views/Courses/CourseRegister"),
      },
      {
        path: "documents",
        name: "CourseDocuments",
        component: () => import("@/views/Courses/CourseDocuments"),
      },
      {
        path: "flipper",
        name: "CourseFlipper",
        component: () => import("@/views/Courses/CourseFlipper"),
      },
      {
        path: "dice",
        name: "CourseDice",
        component: () => import("@/views/Courses/CourseDice"),
      },
    ],
  },
];
