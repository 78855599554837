//import Vue from 'vue';

export default [
  {
    path: "/events",
    component: () => import("@/views/Events/"),
    children: [
      {
        path: "",
        redirect: "list",
      },
      {
        path: "list",
        name: "EventList",
        component: () => import("@/views/Events/EventList"),
      },
      {
        path: "holidays",
        name: "Holidays",
        component: () => import("@/views/Events/Holidays"),
      },
      {
        path: "calendar",
        name: "EventCalendar",
        component: () => import("@/views/Events/EventCalendar"),
      },
    ],
  },
  {
    path: "/optionalStatistics",
    name: "OptionalStatistics",
    component: () => import("@/views/Optional/Statistics.vue"),
    children: [
      {
        path: ":subject",
        name: "OptionalStatisticsSubject",
        component: () => import("@/views/Optional/StatisticsSubject.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/portfolios",
    name: "Portfolios",
    component: () => import("@/views/Portfolios/"),
    children: [
      {
        path: "/portfolios/:id",
        name: "PortfolioDetail",
        component: () => import("@/views/Portfolios/Detail.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/thesis",
    component: () => import("@/views/Thesis/"),
    children: [
      {
        path: "",
        name: "Thesis",
        component: () => import("@/views/Thesis/Mine.vue"),
        props: true,
        children: [
          {
            path: "title/:id",
            name: "ThesisTitleEdit",
            component: () => import("@/views/Thesis/EditTitle.vue"),
            props: true,
          },
        ],
      },
      {
        path: "list",
        name: "ThesisList",
        component: () => import("@/views/Thesis/List.vue"),
        props: true,
        children: [
          {
            path: "edit/:id",
            name: "ThesisEdit",
            component: () => import("@/views/Thesis/Edit.vue"),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/quality",
    component: () => import("@/views/Quality/"),
    children: [
      {
        path: "",
        name: "Quality",
        redirect: "activities",
      },
      {
        path: "activities",
        name: "QualityActivities",
        component: () => import("@/views/Quality/Activities.vue"),
      },
      {
        path: "documents",
        name: "QualityDocuments",
        component: () => import("@/views/Quality/Documents.vue"),
      },
      {
        path: "feedbacks",
        name: "QualityFeedbacks",
        component: () => import("@/views/Quality/Feedbacks.vue"),
      },
      {
        path: "schoolclasses",
        name: "QualitySchoolclasses",
        component: () => import("@/views/Quality/Schoolclasses.vue"),
      },
      {
        path: "edit",
        name: "QualityEdit",
        component: () => import("@/views/Quality/Edit.vue"),
        children: [
          {
            path: "activity/:id",
            name: "QualityEditActivity",
            component: () => import("@/views/Quality/EditActivity.vue"),
            props: true,
          },
          {
            path: "feedback/:id",
            name: "QualityEditFeedback",
            component: () => import("@/views/Quality/EditFeedback.vue"),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/locker",
    name: "Locker",
    component: () => import("@/views/Intern.vue"),
    props: {
      title: "Schäftli-Verwaltung",
      color: "success",
      page: "locker",
    },
  },
];
