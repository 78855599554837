export default {
  app: "intern",
  backendServer: {
    dev: "http://localhost:9000/",
    test: "https://api2.gymkirchenfeld.ch/",
    prod: "https://api.gymkirchenfeld.ch/",
  },
  tokenRefreshOffsetMinutes: 10,
  msal: {
    clientId: "e391461f-c970-499b-81dd-93c439437e63",
    tenantId: "6f499716-de74-4a5b-9a1e-806795a9e947",
  },
};
