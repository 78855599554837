import "@mdi/font/css/materialdesignicons.css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// Global Components
import BtnSpacer from "@/components/global/BtnSpacer.vue";
import Page from "@/components/global/Page.vue";
import Icon from "@/components/global/Icon.vue";
import ListIcon from "@/components/global/ListIcon.vue";
import DebugInfo from "@/components/global/DebugInfo.vue";
import Markdown from "@/components/global/Markdown.vue";
import HideZero from "@/components/global/HideZero.vue";

// Locale
import de from "vuetify/lib/locale/de";

Vue.component("BtnSpacer", BtnSpacer);
Vue.component("Page", Page);
Vue.component("DebugInfo", DebugInfo);
Vue.component("Markdown", Markdown);
Vue.component("HideZero", HideZero);
Vue.component("Icon", Icon);
Vue.component("ListIcon", ListIcon);

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    current: "de",
    locales: { de },
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#3348B5",
        secondary: "#41A7D1",
        accent: "#E8CC00",
        error: "#FCA400",
        info: "#41A7D1",
        success: "#74BF3D",
        warning: "#E8CC00",
        danger: "#ea4335",
        grey: "#666666",
      },
      dark: {
        primary: "#FCA400",
        secondary: "#41A7D1",
        accent: "#3348B5",
        error: "#FCA400",
        info: "#41A7D1",
        success: "#74BF3D",
        warning: "#E8CC00",
        danger: "#ea4335",
        grey: "#666666",
      },
    },
  },
});
