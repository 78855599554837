export default [
  {
    path: "/student/absences",
    name: "StudentAbsences",
    component: () => import("@/views/Absences/indexStudent.vue"),
    children: [
      {
        path: "justification/:justificationId",
        name: "JustificationStudentDetail",
        component: () => import("@/views/Absences/JustificationDetail"),
        props: true,
      },
      {
        path: "justification/:justificationId/edit",
        name: "JustificationStudentEdit",
        component: () => import("@/views/Absences/JustificationEdit"),
        props: true,
      },
    ],
  },
  {
    path: "/student/dispensations",
    name: "StudentDispensations",
    component: () => import("@/views/Dispensations/Student.vue"),
    props: true,
  },
  {
    path: "/student/optional",
    component: () => import("@/views/Optional/Student.vue"),
    children: [
      {
        path: "",
        name: "StudentOptional",
        component: () => {
          return import("@/views/Optional/Details.vue");
        },
      },
    ],
  },
  {
    path: "/student/schoolclass",
    component: () => import("@/views/SchoolClasses/Student.vue"),
    children: [
      {
        path: "",
        name: "StudentSchoolClass",
        redirect: { name: "StudentSchoolClassRegister" },
      },
      {
        path: "list",
        name: "StudentSchoolClassList",
        component: () => import("@/views/SchoolClasses/SchoolClassList.vue"),
      },
      {
        path: "register",
        name: "StudentSchoolClassRegister",
        component: () =>
          import("@/views/SchoolClasses/SchoolClassRegister.vue"),
      },
      {
        path: "exams",
        name: "StudentSchoolClassExams",
        component: () => import("@/views/SchoolClasses/SchoolClassExams.vue"),
      },
      {
        path: "team",
        name: "StudentSchoolClassTeam",
        component: () => import("@/views/SchoolClasses/SchoolClassTeam.vue"),
      },
    ],
  },
];
