/**
 * Provides a Vue plugin for MSAL authentication.
 **/
import * as msal from "@azure/msal-browser";

class Msal {
  initConfiguration(config) {
    this.tenantId = config.msal.tenantId;
    this.msalConfig = {
      auth: {
        authority:
          "https://login.microsoftonline.com/" + config.msal.tenantId + "/",
        clientId: config.msal.clientId,
      },
    };
    this.tokenRefreshOffsetMinutes = config.tokenRefreshOffsetMinutes;
  }

  findAccount() {
    const accounts = this.msalInstance.getAllAccounts();
    if (!accounts) return;
    for (let i = 0; i < accounts.length; ++i) {
      const account = accounts[i];
      if (this.tenantId == account.tenantId) return account;
    }
  }

  async initialize(callback) {
    if (this.msalConfig) await this.initMsal(callback);
  }

  async initMsal(callback) {
    this.msalInstance = new msal.PublicClientApplication(this.msalConfig);
    const response = await this.msalInstance.handleRedirectPromise();
    if (response) {
      this.msalInstance.setActiveAccount(response.account);
      callback.loggedIn(response.account);
    } else {
      const account = this.findAccount();
      if (account) {
        this.msalInstance.setActiveAccount(account);
        callback.loggedIn(account);
      } else {
        this.login();
      }
    }
  }

  async getToken() {
    if (!this.msalConfig) return;
    const request = {
      scopes: [this.msalConfig.auth.clientId + "/.default"],
    };
    let response;
    try {
      response = await this.msalInstance.acquireTokenSilent(request);
    } catch (error) {
      this.login();
    }
    const now = new Date();
    const tokenValidityMs = response.expiresOn.getTime() - now.getTime();
    if (tokenValidityMs <= 0) return;
    const refreshTime =
      tokenValidityMs - this.tokenRefreshOffsetMinutes * 60000;
    clearTimeout(this.tokenRefreshTimer);
    this.tokenRefreshTimer = setTimeout(async () => {
      this.getToken();
    }, refreshTime);
    return response.accessToken;
  }

  async login() {
    const loc = window.location;
    const request = {
      redirectUri: loc.protocol + "//" + loc.host + "/",
      scopes: ["User.Read", "openid", "profile", "offline_access", "email"],
    };
    this.msalInstance.loginRedirect(request);
  }

  async logout() {
    clearTimeout(this.tokenRefreshTimer);
    this.msalInstance.logoutRedirect();
  }
}

const msalInstance = new Msal();

export default {
  async install(Vue) {
    Vue.prototype.$auth = msalInstance;
  },
};
