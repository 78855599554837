<template>
  <iframe
    style="padding: 1rem"
    frameborder="0"
    marginwidth="0"
    marginheight="0"
    hspace="0"
    vspace="0"
    width="100%"
    height="100%"
    :src="src"
  />
</template>
<script>
export default {
  name: 'InternEmbed',
  props: ['page'],
  computed: {
    src() {
      return this.backendServer() + this.page;
    },
  },
};
</script>
