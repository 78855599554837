export default [
  {
    path: "/timetableChanges",
    name: "TimetableChanges",
    component: () => import("@/views/Intern.vue"),
    props: {
      title: "Stundenplanänderungen",
      color: "info",
      page: "register/lessonChanges",
    },
  },
  {
    path: "/weekOverview",
    name: "WeekOverview",
    component: () => import("@/views/Intern.vue"),
    props: {
      title: "Wochenübersicht",
      color: "info",
      page: "register/weekOverview",
    },
  },
];
