<template>
  <v-expansion-panels class="mt-4" v-if="!isProd()" dark>
    <v-expansion-panel>
      <v-expansion-panel-header> Debug Info </v-expansion-panel-header>
      <v-expansion-panel-content>
        <pre v-html="value"></pre>
        <pre><slot></slot></pre>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: 'DebugInfo',
  props: ['value'],
  data() {
    return { shown: false };
  },
};
</script>
