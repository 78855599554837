export default [
  {
    path: "/attendancecheck",
    component: () => import("@/views/AttendanceChecks/"),
    children: [
      {
        path: "",
        name: "AttendanceChecks",
        redirect: { name: "AttendanceChecksCurrent" },
      },
      {
        path: "current",
        name: "AttendanceChecksCurrent",
        component: () => import("@/views/AttendanceChecks/Current.vue"),
        props: true,
      },
      {
        path: "archive",
        name: "AttendanceChecksArchive",
        component: () => import("@/views/AttendanceChecks/Archive.vue"),
      },
      {
        path: "special",
        name: "AttendanceChecksSpecial",
        component: () => import("@/views/AttendanceChecks/Special.vue"),
        children: [
          {
            path: "add",
            name: "AttendanceChecksSpecialAdd",
            component: () => import("@/views/AttendanceChecks/SpecialAdd.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/attendancecheck/:course/:date/:period",
    component: () => import("@/views/AttendanceCheck/"),
    props: true,
    children: [
      {
        path: "",
        name: "AttendanceCheck",
        redirect: { name: "AttendanceCheckPanel" },
      },
      {
        path: "panel",
        name: "AttendanceCheckPanel",
        component: () => import("@/views/AttendanceCheck/Panel.vue"),
      },
      {
        path: "list",
        name: "AttendanceCheckList",
        component: () => import("@/views/AttendanceCheck/List.vue"),
      },
    ],
  },
];
