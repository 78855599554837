export default [
  {
    path: "/invoice",
    component: () => import("@/views/Invoice/"),
    children: [
      {
        path: "",
        name: "Invoice",
        redirect: { name: "InvoicePending" },
      },
      {
        path: "pending",
        name: "InvoicePending",
        component: () => import("@/views/Invoice/Pending.vue"),
      },
      {
        path: "submitted",
        name: "InvoiceSubmitted",
        component: () => import("@/views/Invoice/Submitted.vue"),
      },
      {
        path: "archived",
        name: "InvoiceArchived",
        component: () => import("@/views/Invoice/Archived.vue"),
      },
      {
        path: "events",
        name: "InvoiceEvents",
        component: () => import("@/views/Invoice/Events.vue"),
        children: [
          {
            path: ":id",
            name: "InvoiceEventDetails",
            component: () => import("@/views/Invoice/EventDetails.vue"),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/invoice/new",
    name: "InvoiceNew",
    component: () => import("@/views/Invoice/Edit.vue"),
    props: { id: 0 },
  },
  {
    path: "/invoice/:id",
    name: "InvoiceDetails",
    component: () => import("@/views/Invoice/Details.vue"),
    props: true,
  },
  {
    path: "/invoice/:id/edit",
    name: "InvoiceEdit",
    component: () => import("@/views/Invoice/Edit.vue"),
    props: true,
  },
];
