<template>
  <v-app>
    <v-system-bar app v-if="apiIsTest" color="red" window>
      <v-icon>mdi-bug</v-icon>
      <strong v-if="!$vuetify.breakpoint.mobile"
        >Du befindest dich auf intern2, unserer Test-Plattform!</strong
      ><strong v-else>intern2</strong>

      <v-spacer />
      <span v-if="!$vuetify.breakpoint.xs"
        >Zugriff auf die echten Daten erfolgt über</span
      >
      <v-btn text small href="https://intern.gymkirchenfeld.ch"
        >intern.gymkirchenfeld.ch</v-btn
      >
    </v-system-bar>
    <v-navigation-drawer
      :dark="june14"
      :style="menuStyle"
      v-model="drawer"
      app
      mobile-breakpoint="1000"
    >
      <v-list nav dense v-if="profileLoaded">
        <v-list-item link :to="profileLink">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ title }}</v-list-item-title>
            <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list nav dense>
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Startseite</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <AppMenu v-if="profileLoaded"></AppMenu>

      <v-divider class="mt-8"></v-divider>

      <v-list dense>
        <v-list-item v-if="develop">
          <v-list-item-action>
            <v-switch inset v-model="local"></v-switch>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ apiMode }}</v-list-item-title>
            <v-list-item-subtitle>{{ server }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <form
          :action="server + 'loginToken'"
          method="post"
          target="loginFrame"
          style="display: none"
        >
          <input type="hidden" name="token" :value="token" />
          <button ref="loginButton" type="submit"></button>
        </form>
        <v-list-item>
          <v-btn class="mt-2" depressed block @click="logout"
            ><v-icon left>mdi-logout</v-icon> Logout
          </v-btn>
        </v-list-item>
      </v-list>
      <iframe
        name="loginFrame"
        src=""
        id="loginFrame"
        width="0"
        height="0"
        style="display: none"
      />
      <form
        v-if="!isSafariMobile"
        :action="server + 'logoutToken'"
        method="post"
        target="logoutFrame"
        style="display: none"
      >
        <button ref="logoutButton" type="submit"></button>
      </form>
      <iframe
        name="logoutFrame"
        src=""
        id="logoutFrame"
        width="0"
        height="0"
        style="display: none"
      />
    </v-navigation-drawer>

    <v-main>
      <router-view v-if="profileLoaded"></router-view>
      <ConfirmDialog ref="confirm" />
      <PromptDialog ref="prompt" />
      <NoConnectionDialog ref="noconnection"></NoConnectionDialog>
      <Notifications ref="notifications" />
    </v-main>
  </v-app>
</template>

<script>
import AppMenu from "@/AppMenu.vue";
import ConfirmDialog from "@/components/app/ConfirmDialog.vue";
import NoConnectionDialog from "@/components/app/NoConnectionDialog";
import Notifications from "@/components/app/Notifications.vue";
import PromptDialog from "@/components/app/PromptDialog.vue";
import { isSafariMobile } from "common/utils/helper.js";

export default {
  name: "App",
  components: {
    AppMenu,
    ConfirmDialog,
    NoConnectionDialog,
    Notifications,
    PromptDialog,
  },
  data() {
    return {
      drawer: true,
      profileLoaded: false,
      server: "",
      local: false,
      token: "",
    };
  },
  computed: {
    isSafariMobile() {
      return isSafariMobile();
    },
    apiMode() {
      return this.local ? "Entwicklung" : "Test";
    },
    develop() {
      return process.env.NODE_ENV === "development";
    },
    june14() {
      const today = new Date();
      return today.getDate() == 14 && today.getMonth() == 5;
    },
    menuStyle() {
      return this.june14 ? "background: rgb(97, 65, 125)" : "";
    },
    profileLink() {
      if (!this.$_profilePerson.id) return {};
      return {
        name: "MyProfile",
        params: { id: this.$_profilePerson.id },
      };
    },
    title() {
      return (
        this.$_profilePerson.firstName + " " + this.$_profilePerson.lastName
      );
    },
    subtitle() {
      if (this.develop) {
        return this.server
          ? this.server
              .replace("https://", "")
              .replace("http://", "")
              .replace("/", "")
          : "";
      }
      return this.$_profilePerson.ext;
    },
  },
  watch: {
    local() {
      this.setLocalBackend(this.local);
      this.updateBackend();
    },
  },
  methods: {
    async setAccount() {
      await this.$_loadProfile();
      this.profileLoaded = true;
      this.token = await this.$auth.getToken();
      if (!this.isSafariMobile) {
        requestAnimationFrame(() => {
          this.$refs.loginButton.click();
        });
      }
    },
    logout() {
      requestAnimationFrame(() => {
        this.$refs.logoutButton.click();
        window.setTimeout(() => {
          this.$auth.logout();
        }, 1000);
      });
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    updateBackend() {
      this.server = this.backendServer();
    },
  },
  created() {
    this.local = this.isLocalBackend();
    this.updateBackend();
  },
  mounted() {
    this.$root.confirm = this.$refs.confirm.open;
    this.$root.showNotification = this.$refs.notifications.show;
    this.$root.showError = this.$refs.notifications.showError;
    this.$root.showInfo = this.$refs.notifications.showInfo;
    this.$root.showSuccess = this.$refs.notifications.showSuccess;
    this.$root.prompt = this.$refs.prompt.open;
    this.$root.toggleDrawer = this.toggleDrawer;
    const callback = {
      loggedIn: this.setAccount,
      noConnection: this.$refs.noconnection.show,
      serverInfo: this.setServerInfo,
    };
    this.$auth.initialize(callback);

    window.addEventListener(
      "updateAvailable",
      async () => {
        if (
          await this.$root.confirm({
            message: "Es wurde ein Update gefunden.<br>Bitte Seite neu laden!",
            color: "primary",
            icon: "mdi-information",
          })
        ) {
          window.location.reload();
        }
      },
      false
    );
  },
};
</script>
