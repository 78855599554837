export default [
  {
    path: "/placement",
    component: () => import("@/views/Placement/"),
    name: "Placement",
    children: [
      {
        path: "institutions",
        name: "PlacementInstitutions",
        component: () => import("@/views/Placement/Institutions.vue"),
        children: [
          {
            path: ":id",
            name: "PlacementInstitutionDetail",
            component: () => import("@/views/Placement/InstitutionDetail.vue"),
            props: true,
          },
          {
            path: ":id/edit",
            name: "PlacementInstitutionEdit",
            component: () => import("@/views/Placement/InstitutionEdit.vue"),
            props: true,
          },
        ],
      },
      {
        path: "places",
        name: "PlacementPlaces",
        component: () => import("@/views/Placement/Places.vue"),
      },
      {
        path: "allocation",
        name: "PlacementAllocation",
        component: () => import("@/views/Placement/Allocation.vue"),
      },
      {
        path: "visits",
        name: "PlacementVisits",
        component: () => import("@/views/Placement/Visits.vue"),
      },
      {
        path: "portfolios",
        name: "PlacementPortfolios",
        component: () => import("@/views/Placement/Portfolios.vue"),
        children: [
          {
            path: ":id",
            name: "PlacementPortfolioDetail",
            component: () => import("@/views/Portfolios/Detail.vue"),
            props: true,
          },
        ],
      },
    ],
  },
];
