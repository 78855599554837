export function getHideIcons() {
  return localStorage.getItem("hideIcons") != null;
}

export function setHideIcons(hideIcons) {
  if (hideIcons) {
    localStorage.setItem("hideIcons", "true");
  } else {
    localStorage.removeItem("hideIcons");
  }
}

export function absenceStatusColor(absence) {
  if (!absence || !absence.status.code) return "";
  switch (absence.status.code) {
    case "excused":
      return "success";
    case "halfDay":
      return "info";
    case "justified": // zurückgewiesen (rejected)
      return "success";
    case "pending": // entschuldigt (excused)
      return "info";
    case "present": // unentschuldigt (unexcused)
      return "warning";
    case "unexcused": // gelöscht (deleted)
      return "danger";
    default:
      return "";
  }
}

export function absenceIcon(absence, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (!absence || !absence.status.code) return "";
  switch (absence.status.code) {
    case "pending":
      return "mdi-account-clock";
    case "unexcused":
      return "mdi-account-alert";
    default:
      return justificationCategoryIcon(absence.justificationCategory);
  }
}

export function eventColor(event) {
  if (event.current) {
    return "primary";
  }

  if (event.past) {
    return "grey";
  }
  if (event.canceled) {
    return "danger  ";
  }
  if (event.changed) {
    return "error  ";
  }
  return "success";
}

export function eventIcon(event, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (event.canceled) {
    return "mdi-calendar-minus";
  }
  if (event.changed) {
    return "mdi-calendar-edit";
  }
  if (event.staffOnly) {
    return "mdi-calendar-account";
  }

  return "mdi-calendar";
}

export function justificationCategoryIcon(justificationType, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (!justificationType || !justificationType.code) {
    return "";
  }
  switch (justificationType.code) {
    case "halfDay":
      return "mdi-circle-half-full";
    case "illness":
      return "mdi-emoticon-sick-outline";
    case "injury":
      return "mdi-account-injury";
    case "doctor":
      return "mdi-medical-bag";
    case "death":
      return "mdi-human-male-female-child";
    case "other":
      return "mdi-help";
    case "unknown":
      return "mdi-account-question-outline";
  }
}

export function justificationTypeIcon(justificationCategory, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (!justificationCategory || !justificationCategory.code) {
    return "";
  }
  switch (justificationCategory.code) {
    case "halfDayMorning":
    case "halfDayAfternoon":
      return "mdi-circle-half-full";
    case "excuse":
      return "mdi-bed";
  }
}

export function justificationStatusColor(status) {
  if (!status || !status.code) return "";
  switch (status.code) {
    case "created": // erstellt (created)
      return "info";
    case "submitted": // eingereicht (submitted)
      return "error";
    case "rejected": // zurückgewiesen (rejected)
      return "error";
    case "approved": // bewilligt (approved)
      return "success";
    case "accepted": // akzeptiert (accepted)
      return "success";
    case "unexcused": // unentschuldigt (unexcused)
      return "danger";
    case "deleted": // gelöscht (deleted)
      return "grey";
    default:
      return "";
  }
}

export function justificationStatusIcon(status, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (!status || !status.code) return "";
  switch (status.code) {
    case "created": // erstellt (created)
      return "mdi-plus";
    case "submitted": // eingereicht (submitted)
      return "mdi-mailbox-open-outline";
    case "rejected": // zurückgewiesen (rejected)
      return "mdi-reply-outline";
    case "approved": // bewilligt (approved)
      return "mdi-clock-check-outline";
    case "accepted": // akzeptiert (accepted)
      return "mdi-check";
    case "unexcused": // unentschuldigt (unexcused)
      return "mdi-close";
    case "deleted": // gelöscht (deleted)
      return "mdi-delete-outline";
    default:
      return "";
  }
}

export function lessonStatusColor(status) {
  if (!status || !status.code) return "grey";
  switch (status.code) {
    case "asPlanned":
      return "success";
    case "canceled":
      return "danger";
    case "canceledByEvent":
      return "error";
    case "commented":
      return "info";
    case "inserted":
      return "success";
    case "roomChange":
      return "warning";
    default:
      return "";
  }
}

export function lessonStatusIcon(status, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (!status || !status.code) return "";
  switch (status.code) {
    case "asPlanned":
      return "";
    case "canceled":
      return "mdi-minus-thick";
    case "canceledByEvent":
      return "mdi-minus-thick";
    case "commented":
      return "mdi-comment-outline";
    case "inserted":
      return "mdi-plus-thick";
    case "roomChange":
      return "mdi-map-marker";
    default:
      return "";
  }
}

export function personFlagIcon(code) {
  switch (code) {
    case "G": // Gastschüler:in
      return "mdi-earth";
    case "H": // Hospitant/in
      return "mdi-earth-plus";
    case "KC": // Klassenchef:in
      return "mdi-account-multiple";
    case "NAM": // Nachteilsausgleich
      return "mdi-clock-plus-outline";
    case "SOK": // SOK
      return "mdi-bank";
    case "TC": // Technikchef:in
      return "mdi-wrench";
    case "V": // Austausch (ursprünglich Waadt)
      return "mdi-earth-arrow-left";
    case "E": // Eintritt
      return "mdi-import";
    default:
      return "mdi-square";
  }
}

export function subjectIcon(subject, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (!subject || !subject.code) {
    return "";
  }
  const subjectCode = subject.code
    .replace("EF ", "")
    .replace("SF ", "")
    .replace(" PR", "")
    .trim();
  if (subjectCode.startsWith("NExt")) {
    //  TODO: nExt-subjectcode einfügen
    return "mdi-nature-people";
  }
  switch (subjectCode) {
    case "A":
      return "mdi-weather-night";
    case "AM":
    case "PAM":
      return "mdi-math-compass";
    case "B":
    case "Be":
    case "Bf":
      return "mdi-flower";
    case "BB":
      return "mdi-saxophone";
    case "BG":
    case "BG+":
    case "BGA":
    case "BGi":
      return "mdi-palette";
    case "C":
    case "CP+":
      return "mdi-flask-outline";
    case "BC":
      return "mdi-microscope";
    case "CAE":
    case "CPE":
    case "DALF":
    case "DELF":
      return "mdi-certificate";
    case "CAN":
      return "mdi-laptop";
    case "CHOR":
      return "mdi-account-music";
    case "D":
    case "BSKD":
    case "DaZ1":
    case "DaZ2":
    case "Dba":
      return "mdi-book-open-variant";
    case "E":
      return "mdi-tea";
    case "F":
    case "Fb":
    case "Fba":
    case "FfZ1":
    case "FfZ2":
      return "mdi-eiffel-tower";
    case "G":
    case "Ge":
    case "Gf":
      return "mdi-newspaper-variant-outline";
    case "GG":
      return "mdi-earth";
    case "I":
      return "mdi-pizza";

    case "IN":
    case "IN+":
      return "mdi-laptop";

    case "IN PR":
      return "mdi-memory";
    case "TK":
      return "mdi-led-on";

    case "KS":
      return "mdi-account-group";
    case "L":
      return "mdi-alphabet-latin";
    case "M":
    case "Me":
    case "Mf":
    case "M PV":
      return "mdi-calculator-variant";
    case "M+":
      return "mdi-matrix";
    case "Mba":
      return "mdi-abacus";
    case "MSU":
      return "mdi-account-tie";
    case "MU":
    case "MU+":
    case "MUi":
      return "mdi-music";
    case "ORCH":
      return "mdi-instrument-triangle";
    case "P":
      return "mdi-cog";

    case "PH":
    case "PP":
    case "PPe":
    case "PPP":
      return "mdi-head-dots-horizontal";

    case "POL":
      return "mdi-account-voice";
    case "R":
      return "mdi-scale-balance";
    case "RKDH":
      return "mdi-account-multiple-outline";
    case "RU":
      return "mdi-alphabet-cyrillic";
    case "S":
      return "mdi-guitar-acoustic";

    case "SP":
    case "SP F":
    case "SP M":
    case "SP G":
      return "mdi-volleyball";

    case "RED":
      return "mdi-newspaper";

    case "RL":
    case "RLe":
      return "mdi-dots-circle";

    case "THAT":
    case "THB":
      return "mdi-drama-masks";

    case "BW":
    case "EWR":
    case "VW":
    case "WR":
    case "WR+":
      return "mdi-finance";

    case "VEMS":
      return "mdi-medical-bag";
    case "YOGA":
      return "mdi-yoga";
    default:
      return "";
  }
}

export function noteIcon(note, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (!note || !note.type || !note.type.code) {
    return "";
  }
  switch (note.type.code) {
    case "homework":
      return "mdi-progress-check";
    case "information":
      return "mdi-text";
    case "exam":
      if (note.plannedExam) {
        return "mdi-octagram";
      }
      return "mdi-octagram-outline";
    default:
      return "";
  }
}

export function noteColor(note) {
  if (!note || !note.type || !note.type.code) {
    return "";
  }
  switch (note.type.code) {
    case "exam":
      return note.plannedExam ? "danger" : "error";
    case "information":
      return "info";
    case "homework":
      return "success";
    default:
      return "";
  }
}

export function holidayIcon(holiday, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (!holiday) {
    return "";
  }
  switch (holiday.name) {
    case "Herbstferien":
      return "mdi-leaf";
    case "Winterferien":
      return "mdi-pine-tree";
    case "Februarferien":
      return "mdi-snowboard";
    case "Frühlingsferien":
      return "mdi-flower";
    case "Sommerferien":
      return "mdi-beach";
    default:
      return "";
  }
}

export function reportIcon(report, force) {
  if (!force && getHideIcons()) {
    return "";
  }
  if (report.fileType === "xlsx") {
    return "mdi-file-excel-box";
  }
  if (report.fileType === "pdf") {
    return "mdi-file-pdf-box";
  }
  if (report.fileType === "csv") {
    return "mdi-file-table-box";
  }
  if (report.fileType === "zip") {
    return "mdi-folder-zip";
  }
  return "mdi-help-box";
}

export function reportColor(report) {
  if (report.fileType === "xlsx") {
    return "#1D6F42";
  }
  if (report.fileType === "pdf") {
    return "#F40F02";
  }
  if (report.fileType === "csv") {
    return "#7BBFCC";
  }
  if (report.fileType === "zip") {
    return "#F8D775";
  }
  return "";
}
