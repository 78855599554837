export default [
  {
    path: "/schoolclass",
    name: "SchoolClasses",
    component: () => import("@/views/SchoolClasses/"),
  },
  {
    path: "/schoolclass/:id",
    component: () => import("@/views/SchoolClasses/SchoolClass"),
    props: (route) => {
      const id = Number.parseInt(route.params.id, 10);
      if (Number.isNaN(id)) {
        return 0;
      }
      return { id };
    },
    children: [
      {
        path: "",
        name: "SchoolClass",
        redirect: { name: "SchoolClassRegister" },
      },
      {
        path: "list",
        name: "SchoolClassList",
        component: () => import("@/views/SchoolClasses/SchoolClassList"),
      },
      {
        path: "panel",
        name: "SchoolClassPanel",
        redirect: { name: "SchoolClassList" },
      },
      {
        path: "register",
        name: "SchoolClassRegister",
        component: () => import("@/views/SchoolClasses/SchoolClassRegister"),
      },
      {
        path: "exams",
        name: "SchoolClassExams",
        component: () => import("@/views/SchoolClasses/SchoolClassExams"),
      },
      {
        path: "documents",
        name: "SchoolClassDocuments",
        component: () => import("@/views/SchoolClasses/SchoolClassDocuments"),
      },
      {
        path: "team",
        name: "SchoolClassTeam",
        component: () => import("@/views/SchoolClasses/SchoolClassTeam"),
      },
    ],
  },
];
