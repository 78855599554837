<template>
  <v-icon v-bind="$attrs" v-on="{ ...$listeners }" v-if="!getHideIcons"
    ><slot></slot
  ></v-icon>
</template>
<script>
import { getHideIcons } from "common/utils/icons.js";
export default {
  name: "Icon",
  props: {},
  computed: {
    getHideIcons,
  },
};
</script>
