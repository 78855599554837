import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// Unterricht
import routesSchoolClass from "./routes.schoolclass.js";
import routesCourse from "./routes.course.js";
import routesInvoice from "./routes.invoice.js";
import routesAttendanceCheck from "./routes.attendancecheck.js";
import routesTimetable from "./routes.timetable.js";
import routesRooms from "./routes.rooms.js";

// Meine Klasse
import routesClassTeacher from "./routes.classteacher.js";

// Schule
import routesPeople from "./routes.people.js";
import routesSchule from "./routes.schule.js";

// Informationen
import routesInformation from "./routes.information.js";

// Weiteres
import routesCommon from "./routes.common.js";
import routesPlacement from "./routes.placement.js";

// Students
import routesStudent from "./routes.student.js";

import routesOld from "./routes.old.js";

const routes = [
  ...routesSchoolClass,
  ...routesCourse,
  ...routesInvoice,
  ...routesAttendanceCheck,
  ...routesTimetable,
  ...routesRooms,
  ...routesClassTeacher,
  ...routesPeople,
  ...routesInformation,
  ...routesCommon,
  ...routesStudent,
  ...routesSchule,
  ...routesPlacement,
  ...routesOld,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    const event = new Event("updateAvailable");
    window.dispatchEvent(event);
    if (
      confirm(
        "Es wurde ein Update erkannt. Soll die Seite aktualisiert werden?"
      )
    ) {
      window.location.reload();
    }
  }
});

export default router;
