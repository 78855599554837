export default [
  {
    path: "/absences",
    name: "Absences",
    redirect: { name: "AbsencesPending" },
  },
  {
    path: "/absences/",
    component: () => import("@/views/Absences/"),
    props: true,
    children: [
      {
        path: "balance",
        name: "AbsencesBalance",
        component: () => import("@/views/Absences/Balance"),
        props: true,
      },
      {
        path: "pending",
        name: "AbsencesPending",
        component: () => import("@/views/Absences/Pending"),
        props: true,
        children: [
          {
            path: "justification/:justificationId",
            name: "JustificationPendingDetail",
            component: () => import("@/views/Absences/JustificationDetail"),
            props: true,
          },
          {
            path: "justification/:justificationId/edit",
            name: "JustificationPendingEdit",
            component: () => import("@/views/Absences/JustificationEdit"),
            props: true,
          },
        ],
      },
      {
        path: "overview",
        name: "AbsencesOverview",
        component: () => import("@/views/Absences/Overview"),
        props: true,
        children: [
          {
            path: "add",
            name: "JustificationOverviewAdd",
            component: () => import("@/views/Absences/JustificationEdit"),
            props: true,
          },
        ],
      },
      {
        path: "overview/:id",
        name: "AbsencesOverviewStudent",
        component: () => import("@/views/Absences/Student"),
        props: true,
        children: [
          {
            path: "justification/:justificationId",
            name: "JustificationOverviewDetail",
            component: () => import("@/views/Absences/JustificationDetail"),
            props: true,
          },
          {
            path: "justification/:justificationId/edit",
            name: "JustificationOverviewEdit",
            component: () => import("@/views/Absences/JustificationEdit"),
            props: true,
          },
        ],
      },
      {
        path: "information",
        name: "AbsencesInformation",
        component: () => import("@/views/Absences/Information"),
        props: true,
      },
    ],
  },
  {
    path: "/dispensations",
    name: "Dispensations",
    redirect: { name: "DispensationsView", params: { view: "list" } },
  },
  {
    path: "/dispensations/:view",
    name: "DispensationsView",
    component: () => import("@/views/Dispensations/"),
    props: true,
    children: [
      {
        path: "add",
        name: "DispensationAdd",
        component: () => import("@/views/Dispensations/Add.vue"),
        props: true,
      },
      {
        path: ":id",
        name: "DispensationEdit",
        component: () => import("@/views/Dispensations/Edit.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/finance/schoolclass",
    name: "FinanceSchoolClass",
    component: () => import("@/views/Finance/SchoolClass.vue"),
    props: true,
    children: [
      {
        path: ":id",
        name: "FinanceDetails",
        component: () => import("@/views/Finance/Details.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/lockers",
    name: "Lockers",
    component: () => import("@/views/Lockers/"),
  },
  {
    path: "/optional/schoolclass",
    name: "OptionalSchoolClass",
    component: () => {
      return import("@/views/Optional/SchoolClass.vue");
    },
    children: [
      {
        path: ":id",
        component: () => import("@/views/Optional/DetailsDialog.vue"),
        children: [
          {
            path: "",
            name: "OptionalStudent",
            component: () => import("@/views/Optional/Details.vue"),
            props: ({ params }) => ({
              id: Number.parseInt(params.id, 10) || 0,
            }),
          },
        ],
      },
    ],
  },
];
