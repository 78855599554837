export default [
  {
    title: "Unterricht",
    color: "info",
    visible: true,
    items: [
      {
        title: "mein Unterricht",
        icon: "mdi-account-details",
        to: { name: "MyRegister" },
        roles: ["teacher", "student"],
        self: true,
      },
      {
        title: "Klassen",
        icon: "mdi-account-group",
        to: { name: "SchoolClasses" },
        roles: ["staff"],
      },
      {
        title: "Klasse",
        icon: "mdi-account-group",
        to: { name: "StudentSchoolClass" },
        roles: ["student"],
      },
      {
        title: "Kurse",
        icon: "mdi-school",
        to: { name: "Courses" },
      },
      {
        title: "Personen",
        icon: "mdi-account-multiple",
        to: { name: "People" },
      },
      {
        title: "Rechnungen",
        icon: "mdi-cash-multiple",
        to: { name: "Invoice" },
        roles: ["teacher"],
      },
      {
        title: "Kontrolle",
        icon: "mdi-check",
        to: { name: "AttendanceChecks" },
        roles: ["teacher"],
      },
      {
        title: "Absenzen",
        icon: "mdi-bed",
        to: { name: "StudentAbsences" },
        roles: ["student"],
      },
      {
        title: "Dispensationen",
        icon: "mdi-account-remove",
        to: { name: "StudentDispensations" },
        roles: ["student"],
      },
      {
        title: "Stundenplan",
        icon: "mdi-timetable",
        to: { name: "Timetable" },
      },
      {
        title: "Stundenplanänderungen",
        icon: "mdi-wrench-clock",
        to: { name: "TimetableChanges" },
      },

      {
        title: "Wochenübersicht",
        icon: "mdi-table-large",
        to: { name: "WeekOverview" },
        roles: ["schoolAdmin"],
      },
      {
        title: "Räume",
        icon: "mdi-pin",
        to: { name: "Room" },
        roles: ["staff"],
      },
      {
        title: "Räume",
        icon: "mdi-pin",
        to: {
          name: "RoomReservation",
          params: { rooms: "328" },
        },
        roles: ["sok"],
      },
    ],
  },
  {
    title: "meine Klasse",
    color: "info",
    visible: false,
    items: [
      {
        title: "Absenzenwesen",
        icon: "mdi-bed",
        to: { name: "Absences" },
        roles: ["classTeacher"],
      },
      {
        title: "Dispensationen",
        icon: "mdi-account-remove",
        to: { name: "Dispensations" },
        roles: ["classTeacher"],
      },
      {
        title: "myGymer-Wahl",
        icon: "mdi-star",
        to: { name: "OptionalSchoolClass" },
        roles: ["classTeacher"],
      },
      {
        title: "Finanzen",
        icon: "mdi-account-cash",
        to: { name: "FinanceSchoolClass" },
        roles: ["classTeacher"],
      },
      {
        title: "Schäftli",
        icon: "mdi-locker-multiple",
        to: { name: "Lockers" },
        roles: ["classTeacher"],
      },
    ],
  },
  {
    title: "Schule",
    color: "success",
    visible: true,
    items: [
      {
        title: "Termine",
        icon: "mdi-calendar",
        link: "/events",
      },
      {
        title: "Schulprofil",
        icon: "mdi-cube",
        to: { name: "PersonSchoolProfile" },
        self: true,
        roles: ["student"],
      },
      {
        title: "myGymer-Wahl",
        icon: "mdi-star",
        to: { name: "StudentOptional" },
        roles: ["student"],
      },
      {
        title: "myGymer-Angebote",
        icon: "mdi-star",
        link: "/optionalStatistics",
        roles: ["staff"],
      },
      {
        title: "Maturaarbeiten",
        icon: "mdi-puzzle",
        link: "/thesis",
        roles: ["staff"],
      },
      {
        title: "Maturaarbeit",
        icon: "mdi-puzzle",
        link: "/thesis",
        roles: ["student"],
      },
      {
        title: "Portfolios",
        icon: "mdi-certificate",
        link: "/portfolios",
      },
      {
        title: "Q-Arbeit",
        icon: "mdi-chart-line",
        link: "/quality",
        roles: ["teacher"],
      },
      {
        title: "Schäftli-Verwaltung",
        icon: "mdi-lock",
        link: "/locker",
        roles: ["schoolAdmin"],
      },
    ],
  },
  {
    title: "Informationen",
    color: "error",
    visible: true,
    items: [
      {
        title: "Anzeigetafel",
        icon: "mdi-television",
        link: "/screen",
      },
      {
        title: "Zuständigkeiten",
        icon: "mdi-account-question",
        link: "/roles",
      },
      {
        title: "Fachschaften",
        icon: "mdi-account-details",
        link: "/departments",
        roles: ["staff"],
      },
      {
        title: "Dokumente",
        icon: "mdi-file-document-multiple",
        link: "/documents",
      },
      {
        title: "Linksammlung",
        icon: "mdi-link",
        link: "/links",
      },
      {
        title: "Bilder",
        icon: "mdi-camera",
        link: "/gallery",
      },
      {
        title: "Finanzen",
        icon: "mdi-account-cash",
        link: "/finance/",
        roles: ["student", "teacher"],
      },
      {
        title: "Mein Konto (Anlässe)",
        icon: "mdi-account-cash",
        link: "/finance/events",
        roles: ["student", "teacher"],
        dev: true,
      },
    ],
  },
  {
    title: "Weiteres",
    color: "grey",
    visible: true,
    items: [
      {
        title: "ReleaseNotes",
        icon: "mdi-checkbox-marked-circle-plus-outline",
        to: { name: "ReleaseNotes" },
      },
      {
        title: "FAQ",
        icon: "mdi-help-circle-outline",
        to: { name: "Faq" },
      },
      {
        title: "Einstellungen",
        icon: "mdi-cogs",
        to: { name: "Settings" },
      },
      {
        title: "Stage",
        icon: "mdi-home-group",
        link: "/placement",
        roles: ["placementAdmin"],
      },
    ],
  },
];
