<template>
  <div>
    <div v-for="group in menugroups" :key="group.title">
      <v-list nav dense v-if="filteredItems(group).length > 0">
        <v-list-group v-model="group.visible" :color="group.color">
          <template v-slot:activator>
            <v-list-item-title
              ><span :class="group.color + '--text'">{{ group.title }}</span>
            </v-list-item-title>
          </template>

          <v-list-item
            v-for="(item, index) in filteredItems(group)"
            :key="index"
            link
            :to="item.to"
            @click="menuClick"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.dev">
              <v-icon small>mdi-bug</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </div>
  </div>
</template>
<script>
import menu from "@/menu";
import { bus } from "@/main";

export default {
  data() {
    return {
      count: 0,
      menugroups: menu,
    };
  },
  watch: {
    menugroups: {
      deep: true,
      handler() {
        this.menugroups.forEach((group) => {
          if (group.visible) {
            localStorage.setItem("menuGroup" + group.title, "true");
          } else {
            localStorage.removeItem("menuGroup" + group.title);
          }
        });
      },
    },
  },
  created() {
    this.menugroups.forEach((group) => {
      if (localStorage.getItem("menuGroup" + group.title)) {
        group.visible = true;
      } else {
        group.visible = false;
      }
    });
  },
  methods: {
    filteredItems(group) {
      return group.items
        .filter((item) => {
          return (
            (!item.roles || this.$_hasRole(item.roles)) &&
            (!item.dev || !this.isProd())
          );
        })
        .map((item) => this.calcLink(item));
    },
    calcLink(item) {
      if (item.self) {
        item.to.params = { id: this.$_profilePerson.id };
      }
      item.to = item.to || item.link;
      return item;
    },
    menuClick() {
      bus.$emit("menu");
    },
  },
};
</script>
